import { gql } from "@apollo/client";
import { ORGANIZATION_FRAGMENT } from "./find-organization.query";

export const FIND_ORGANIZATIONS_QUERY = gql`
  query FindOrganizations($isActive: Boolean) {
    findOrganizations(isActive: $isActive) {
        ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const ORGANIZATION_MEMBER_FRAGMENT = gql`
  fragment OrganizationMemberFragment on OrganizationMember {
    id
    isOwner
    email
    user {
      id
      email
      username
      description
    }
    acceptedAt
    invitedAt
  }
`;

export const FIND_ORGANIZATION_MEMBERS = gql`
  query FindOrganizationMembers($id: String!) {
    findOrganization(id: $id) {
      id
      members {
        ...OrganizationMemberFragment
      }
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`;
