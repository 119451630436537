import { gql } from "@apollo/client";

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    slug
    companyId
    form
    displayedName
    isActive
    address
    addressComplement
    zipCode
    city
    countryCode
    phoneNumber
    comments
    email
    taxId
    registration
    allowShippingInvoiceGeneration
    preparationDelay
    inventoryPolicy
    legalEntities {
      id
      name
      companyId
      taxId
      eoriNumber
      email
      registration
      capital
      form
      address
      addressComplement
      zipCode
      city
      countryCode
      state
      phoneNumber
      comments
    }
    eori
    capital
  }
`;

export const FIND_ORGANIZATION = gql`
  query FindOrganization($id: String!) {
    findOrganization(id: $id) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;
