import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Form,
  FormLayout,
  Page,
  Layout,
  Card,
  Image,
  TextContainer,
  TextStyle,
  Banner,
  Link,
  Stack,
  Heading,
  Select,
  Tabs,
  Spinner,
} from "@shopify/polaris";
import { asChoiceField, useField, useForm } from "@shopify/react-form";
import validator from "validator";
import { useAuthDispatchContext } from "../contexts/auth.context";
import { useRouter } from "next/router";
import useTranslator from "../hooks/i18n.hook";
import styles from "./unconnected.module.scss";
import AccountWrapper from "../components/AccountWrapper";
import {
  useApplicationContext,
  useApplicationDispatchContext,
} from "../contexts/application.context";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  useMutation,
} from "@apollo/client";
import { whoAmI } from "../queries/__generated__/whoAmI";
import { WHO_AM_I } from "../queries/who-am-i.query";
import { FIND_ORGANIZATIONS_QUERY } from "../queries/find-organizations.query";
import { FindOrganizations } from "../queries/__generated__/FindOrganizations";
import { LoginOrganization } from "../queries/__generated__/LoginOrganization";
import { LOGIN_ORGANIZATION_MUTATION } from "../queries/login-organization.mutation";
import HubspotForm from 'react-hubspot-form'

interface LoginResult {
  accessToken: string;
  refreshToken: string;
}

function userLoggedInFetch(accessToken?: string) {
  return async (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
    const response = await fetch(uri, {
      ...options,
      headers: {
        ...(options?.headers || {}),
        ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      },
    });

    return response;
  };
}

function Login() {
  const dispatch = useAuthDispatchContext();
  const applicationDispatch = useApplicationDispatchContext();

  const { organizationId, memberships, organizationName } =
    useApplicationContext();

  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const i18n = useTranslator("Login");
  const router = useRouter();
  const { redirectUri } = router.query;

  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject>>(undefined);

  const [loginOrganization] = useMutation<LoginOrganization>(
    LOGIN_ORGANIZATION_MUTATION,
    { client }
  );

  // 1- login stage user login without organizationId
  const [loginResult, setLoginResult] = useState<LoginResult>(undefined);
  const [rememberMe, setRememberMe] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleChangeSelectedTab = useCallback((v) => {
    console.log(v)
    setSelectedTab(v);
  }, [])

  const handleLoadWhoAmI = useCallback(
    async (client: ApolloClient<NormalizedCacheObject>) => {
      const whoAmIResult = await client.query<whoAmI>({
        query: WHO_AM_I,
        fetchPolicy: "no-cache",
      });

      if (whoAmIResult?.data?.whoAmI) {
        dispatch({
          type: "USER",
          payload: {
            user: whoAmIResult.data.whoAmI,
          },
        });
      }
    },
    [dispatch]
  );

  const handleLoginOrganization = useCallback(
    async (
      organizationId: string,
      client: ApolloClient<NormalizedCacheObject>
    ) => {
      const login = await loginOrganization({
        client,
        variables: {
          organizationId,
          clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
        },
      });

      if (!login.data?.loginOrganization) {
        return;
      }

      const accessToken = login.data?.loginOrganization;
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          accessToken: accessToken.accessToken,
          refreshToken: accessToken.refreshToken,
        },
      });

      router.push((redirectUri as string) || "/");
    },
    [dispatch, loginOrganization, redirectUri, rememberMe, router]
  );

  const handleLoadMemberships = useCallback(
    async (client: ApolloClient<NormalizedCacheObject>) => {
      const findOrganizationsResult = await client.query<FindOrganizations>({
        query: FIND_ORGANIZATIONS_QUERY,
        fetchPolicy: "no-cache",
      });

      if (!findOrganizationsResult?.data?.findOrganizations) {
        // setErrors and return
        return;
      }

      const organizations = findOrganizationsResult.data.findOrganizations;
      applicationDispatch({
        type: "MEMBERSHIPS_LOADED",
        payload: {
          memberships: organizations,
        },
      });

      if (organizationId) {
        // IN THIS CASE NO PERMISSIONS FOR THE FIRST CONNECTION IF APPLICATION CONTEXT EXISTS
        const o = organizations.find((o) => o.id === organizationId);

        applicationDispatch({
          type: "ORGANIZATION_SELECTED",
          payload: {
            organizationId: o?.id || organizations[0].id,
            organizationName: o?.name || organizations[0].name,
          },
        });

        handleLoginOrganization(o?.id || organizations[0].id, client);
        return;
      }

      if (!organizationId && organizations?.length === 1) {
        // We only have one organization
        applicationDispatch({
          type: "ORGANIZATION_SELECTED",
          payload: {
            organizationId: organizations[0].id,
            organizationName: organizations[0].name,
          },
        });

        handleLoginOrganization(organizations[0].id, client);
        return;
      }
    },
    [applicationDispatch, handleLoginOrganization, organizationId]
  );

  const handleLoginResult = useCallback(
    (accessToken, refreshToken) => {
      // 1- Create the client used for next requests, which is a temporary client
      setIsLoggedIn(true);

      const client = new ApolloClient({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
        cache: new InMemoryCache(),
        link: ApolloLink.from([
          new HttpLink({
            uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
            fetch: userLoggedInFetch(accessToken),
          }),
        ]),
      });

      setClient(client);

      // load memberships
      handleLoadWhoAmI(client);
      handleLoadMemberships(client);

      if (organizationId) {
        handleLoginOrganization(organizationId, client);
      }
    },
    [handleLoadMemberships, handleLoadWhoAmI, handleLoginOrganization, organizationId]
  );

  const handleSelectOrganization = useCallback(
    (organizationId: string) => {
      const organization = memberships?.find((o) => o.id === organizationId);
      applicationDispatch({
        type: "ORGANIZATION_SELECTED",
        payload: {
          organizationId: organization.id,
          organizationName: organization.name,
        },
      });

      handleLoginOrganization(organization.id, client);
    },
    [applicationDispatch, client, handleLoginOrganization, memberships]
  );

  const { fields, submit, submitting, dirty, reset, submitErrors, makeClean } =
    useForm({
      fields: {
        email: useField({
          value: "",
          validates: [
            (value) =>
              validator.isEmpty(value)
                ? i18n.translate("Login.Form.email.empty")
                : undefined,
            (value) =>
              !validator.isEmail(value)
                ? i18n.translate("Login.Form.email.invalid")
                : undefined,
          ],
        }),
        password: useField({
          value: "",
          validates: [
            (value) =>
              validator.isEmpty(value)
                ? i18n.translate("Login.Form.password.empty")
                : undefined,
          ],
        }),
        rememberMe: useField(false),
      },
      onSubmit: async (fieldValues) => {
        const loginData = {
          username: fieldValues.email,
          password: fieldValues.password,
          grant_type: "password",
          client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
          exp: new Date().getTime() + 10000
        };

        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/oauth2/token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loginData),
          }
        );

        if (response.status === 201) {
          const accessToken = await response.json();
          handleLoginResult(
            accessToken.access_token,
            accessToken.refresh_token
          );
          setRememberMe(true);
          setLoginResult({
            accessToken: accessToken.access_token,
            refreshToken: accessToken.refresh_token,
          });
          return { status: "success" };
        }

        return {
          status: "fail",
          errors: [{ message: i18n.translate("Login.Form.submitFail") }],
        };
      },
    });

  const loginErrorMarkup =
    submitErrors.length > 0 ? (
      <Layout.Section>
        <Banner status="critical">
          {i18n.translate("Login.Form.submitFail")}
        </Banner>
      </Layout.Section>
    ) : null;

  const loginTabMarkup =
    <div>
      <Stack>
        <Stack.Item>
          <TextStyle variation="subdued">
            {i18n.translate("Login.subtitle")}
          </TextStyle>
        </Stack.Item>
      </Stack>
      <br />
      {loginErrorMarkup}
      <Form onSubmit={submit}>
        <FormLayout>
          <TextField
            label={i18n.translate("Login.Form.email.label")}
            type="email"
            {...fields.email}
            autoComplete="off"
          />
          <TextField
            label={i18n.translate("Login.Form.password.label")}
            type="password"
            {...fields.password}
            autoComplete="off"
          />
        </FormLayout>
        <FormLayout>
          <Link url="/password-lost">
            {i18n.translate("Login.forgetPassword")}
          </Link>
          <Checkbox
            label={i18n.translate("Login.Form.rememberMe.label")}
            {...asChoiceField(fields.rememberMe)}
          />
          <Stack distribution="trailing">
            <Stack.Item>
              <Button primary disabled={!dirty} submit loading={submitting}>
                {i18n.translate("Login.Form.submit")}
              </Button>
            </Stack.Item>
          </Stack>
        </FormLayout>
      </Form>
    </div>

  const signupTab =
    <div className="loginSignUp">
      <Card.Section>
        <Stack>
          <Stack.Item>
            <TextStyle variation="subdued">{i18n.translate("Login.signup.subtitle")}</TextStyle>
          </Stack.Item>
        </Stack>
        <br />
        <HubspotForm
          portalId="6049097"
          formId="bf318de5-ea79-4afd-a677-c607b1b66c9d"
          onSubmit={() => { }}
          onReady={(form) => { }}
          loading={<Stack distribution="center"><Stack.Item><Spinner></Spinner></Stack.Item></Stack>}
        />
      </Card.Section>
    </div>

  const loginMarkup = !isLoggedIn ? (
    <div className="unconnected">
      <Page fullWidth>
        <Card>
          <div className="login">
            <Tabs
              fitted
              selected={selectedTab}
              onSelect={handleChangeSelectedTab}
              tabs={[
                {
                  id: "login-tab",
                  content: <Heading>{i18n.translate("Login.title")}</Heading>
                },
                {
                  id: "signup-tab",
                  content: <Heading>{i18n.translate("Login.signup.title")}</Heading>
                }
              ]}>
              {selectedTab === 0 && <Card.Section>{loginTabMarkup}</Card.Section>}
              {selectedTab === 1 && signupTab}
            </Tabs>
          </div>
        </Card>
      </Page>
    </div >
  ) : null;

  const organizationSelectorMarkup =
    isLoggedIn && !organizationId && memberships?.length > 1 ? (
      <div className="unconnected">
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <Layout>
                  <Layout.Section>
                    <Heading>{i18n.translate("Login.title")}</Heading>
                    <TextContainer>
                      <TextStyle variation="subdued">
                        <p>{i18n.translate("Login.subtitle")}</p>
                      </TextStyle>
                    </TextContainer>
                  </Layout.Section>
                  {loginErrorMarkup}
                  <Layout.Section>
                    <FormLayout>
                      <Select
                        label={i18n.translate("Login.choice")}
                        options={[
                          {
                            value: "",
                            label: i18n.translate("Login.select")
                          }
                          ,
                          ...memberships.map((m) => ({
                            value: m.id,
                            label: m.name,
                          }))]}
                        onChange={handleSelectOrganization}
                        value={null}
                      />
                    </FormLayout>
                  </Layout.Section>
                </Layout>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    ) : null;

  return (
    <AccountWrapper>
      <div className={styles.formBlock}>
        <div className={styles.imageFormBlock}>
          <Stack vertical distribution="center" alignment="center">
            <Stack.Item>
              <Image
                source="https://storage.googleapis.com/cdn_files_getbigger/logo-hc%402x.png"
                alt="HappyColis Logo"
                style={{ margin: "auto", display: "block" }}
                width="40%"
              />
            </Stack.Item>
            <Stack.Item>
              <h2 className={styles.text}>
                {i18n.translate("Login.subImage")}
              </h2>
            </Stack.Item>
          </Stack>
        </div>
        {loginMarkup}
        {organizationSelectorMarkup}
      </div>
    </AccountWrapper>
  );
}
export default Login;
