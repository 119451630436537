import { Stack, Image } from '@shopify/polaris';
import useTranslator from '../hooks/i18n.hook';
import styles from '../pages/unconnected.module.scss';


function AccountWrapper(props) {

  const i18n = useTranslator("Login")

  return (
    <div className={styles.font} style={{ display: "flex", justifyContent: "center" }}>
      <div className={styles.imageBlock}>
        <Stack>
          <Stack.Item>
            <Stack vertical distribution="center" alignment="center">
              <Stack.Item>
                <Image
                  source="https://storage.googleapis.com/cdn_files_getbigger/logo-hc%402x.png"
                  alt="HappyColis Logo"
                  style={{ margin: "auto", display: "block" }}
                  width="40%"
                />
              </Stack.Item>
              <Stack.Item>
                <h2 className={styles.text}>{i18n.translate('Login.subImage')}</h2>
              </Stack.Item>
              <Stack.Item>
                <Image
                  source="/logisticien-connect.jpeg"
                  alt="logistic"
                  style={{ margin: "auto", display: "block" }}
                  width="90%"
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
      {props.children}
    </div>
  )
}

export default AccountWrapper